<template>
  <div>
    <!-- <v-card> -->
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
      app
      dark
      color="rgba(1,162,165,1)"
      mini-variant-width="70"
      width="190"
    >
    <v-list two-line>
      <v-list-item >
        <v-avatar size="50" tile class="mr-2">
          <img src="../assets/admin/logo-te.png">
        </v-avatar>

        <!-- <v-list-item-title> Teacher Edition</v-list-item-title> -->
        <v-list-item-content class="py-0">
            <p class="subtitle-1 my-auto">Teacher Edition</p>
          </v-list-item-content>

        <v-btn
          icon
          @click.stop="mini = !mini"
        >
          <v-icon size="40">mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <v-list dense two-line>
        <v-list-item href="/schoolTeacher" >
          <v-list-item-icon class="mr-4">
            <v-icon size="30">mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <p class="subtitle-1">Dashboard</p>
            <!-- <v-list-item-title>Dashboard</v-list-item-title> -->
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/schoolTeacher/classroom">
            <v-list-item-icon class="mr-4">
              <img src="../assets/admin/teacher-home.svg" style="width: 30px; height: 30px;">
            </v-list-item-icon>

          <v-list-item-content>
            <p class="subtitle-1 my-auto" >Classroom</p>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/schoolTeacher/activitieslist">
          <v-list-item-icon class="mr-4">
              <img src="../assets/admin/teaching-tools.svg" style="width: 30px; height: 30px;">
            </v-list-item-icon>

          <v-list-item-content>
            <p class="subtitle-1 my-auto">Teaching Tools</p>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/schoolTeacher/statistics">
          <v-list-item-icon class="mr-4">
              <v-icon size="30">mdi-align-vertical-bottom</v-icon>
            </v-list-item-icon>

          <v-list-item-content>
            <p class="subtitle-1">Reports</p>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/schoolTeacher/resources">
          <v-list-item-icon class="mr-4">
              <v-icon size="30">mdi-file-cog</v-icon>
            </v-list-item-icon>

          <v-list-item-content>
            <p class="subtitle-1">Resources</p>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item to="/schoolTeacher/settings">
          <v-list-item-icon class="mr-4">
              <v-icon size="30">mdi-cog</v-icon>
            </v-list-item-icon>

          <v-list-item-content>
            <p class="subtitle-1">Settings</p>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/schoolTeacher/help" v-if="isClever">
          <v-list-item-icon class="mr-4">
              <v-icon size="30">mdi-help-circle</v-icon>
            </v-list-item-icon>
          <v-list-item-content>
            <p class="subtitle-1">Help Center</p>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
            <v-list-item-action class="mr-4">
              <v-icon size="30">mdi-logout-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <p class="subtitle-1">Logout</p>
            </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main app fluid style="height: 100vh;" >
        <v-snackbar top v-model="snackbar" timeout="4000" color="blue-grey">
            se actualizo correctamente
            <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                Close
            </v-btn>
            </template>
        </v-snackbar>
        <router-view/>
    </v-main>
  <!-- </v-card> -->

    <!-- <v-dialog v-model="dialogSettings" max-width="700">
      <v-card>
        <v-card-title class="headline">
          Set up your account
        </v-card-title>
        <v-row class="ma-5">
          <v-col class="ma-4">
            Change your password
          </v-col>
          <v-col>
            <v-text-field outlined label="Password" prepend-icon="mdi-lock" v-model="password" autocomplete="off"></v-text-field>
          </v-col>
          <v-col class="col-3">
            <v-btn class="ma-2" :loading="loadingPassword" :disabled="loadingPassword" color="secondary" @click="ChangePassword">
              Update
            </v-btn>
          </v-col>
        </v-row>
        <v-alert outlined type="warning" prominent border="left" class="ma-4" v-if="textUpdatePassword != ''">
           {{ textUpdatePassword }}
        </v-alert>
      </v-card>
    </v-dialog> -->

  </div>
</template>
<script>
import { auth,db } from '../plugins/firebase'
export default {
  data: () => ({
    drawer: true,
    group: null,
    dialogSettings: false,
    loadingPassword: false,
    password: '',
    snackbar: false,
    textUpdatePassword: '',
    ButtonBakc: false,
    mini: false,
    isClever: false
  }),
  methods: {
    async logout () {
      await auth().signOut()
      localStorage.removeItem('email');
      localStorage.removeItem('uid');
      localStorage.removeItem('role');
      localStorage.removeItem('token');
      location.href = "/login";
    },
    settings () {
      this.dialogSettings = true;
    },
    goRoute (page) {
      console.log('asd')
      this.$router.push({name: page});
    },
    OpenTe () {
      if (this.$route.name != "activitiesList") {
        location.href = "/schoolTeacher/activitieslist";
      }else{
        this.drawer =false;
      }
    },
    // ChangePassword () {
    //   if (this.password == '') {
    //     this.textUpdatePassword = 'incorrect password';
    //     setTimeout(() => (this.textUpdatePassword = ''), 3000);
    //     return;
    //   }
    //   this.loadingPassword=true;
    //   var user = auth().currentUser;
    //   // console.log(auth().currentUser)
    //   let aux=this;
    //     user.updatePassword(this.password).then(function() {          
    //       aux.dialogSettings = false;
    //       aux.loadingPassword=false;
    //       aux.snackbar =true;
    //     }).catch(function(error) {
    //       console.log(error)
    //       aux.loadingPassword=false;
    //       aux.textUpdatePassword = error.message;
    //       setTimeout(() => (aux.textUpdatePassword = ''), 3000);
    //     });
    // },
    // async Opentools () {
    //   const token = await auth().currentUser.getIdToken();
    //   console.log(token);
    //   window.location.href = "leelee://game?token="+token;
    //   console.log('asd')
    // }
  },
  mounted() {
    console.log(this.$store.state.user.uid)
    if (this.$store.state.user.role != "schoolTeacher") {
      this.logout();
    }
    db.collection('TempTokensClever').doc(this.$store.state.user.uid).get().then((doc) => {
        if(doc.exists){
          isClever=true;
        }
    })
    // let unityLoaderScript = document.createElement('script')
    // let unityProgressScript = document.createElement('script')
    // unityLoaderScript.setAttribute('src', '/unity/Build/UnityLoader.js')
    // unityProgressScript.setAttribute('src', '/unity/TemplateData/UnityProgress.js')
    // document.head.appendChild(unityLoaderScript)
    // document.head.appendChild(unityProgressScript)
  },
}
</script>
<style>
  .icono-lateral img{
    width: 25px;
    height: 25px;
    margin-right: 30px;
  }
  .centrar-imagen {
    margin-left: auto;
    margin-right: auto;
  }
  .titulo-card{
    font-size: 1.7em;
    font-weight: 800;
    color: #01a2a5;
  }
</style>
