import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from '../plugins/firebase';
import JWTDecode from 'jwt-decode'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      email: localStorage.getItem('email'),
      uid: localStorage.getItem('uid'),
      role: localStorage.getItem('role'),
      token :localStorage.getItem('token')
    }
  },
  mutations: {
    SET_USER: (state, account) => {
      state.user = account;
      localStorage.setItem('email', account.email)
      localStorage.setItem('uid', account.uid)
      localStorage.setItem('role', account.role)
      localStorage.setItem('token', account.token)
    }
  },
  actions: {
    async login ({ commit }, account) {
      try {
        await auth().signInWithEmailAndPassword(account.email, account.password);
  
        const token = await auth().currentUser.getIdToken();
        console.log(token);
        const decoded = JWTDecode(token);
        console.log(decoded)
        let role = null;
        if (decoded.superAdmin) {
          role = 'superAdmin'
        }
        if (decoded.schoolAdmin) {
          role = 'schoolAdmin'
        }
        if (decoded.schoolTeacher) {
          role = 'schoolTeacher'
        }
        if (decoded.parent) {
          role = 'parent'
        }
        if (decoded.individualTeacher) {
          role = 'individualTeacher'
        }
        if (decoded.student) {
          role = 'student'
        }
        if (decoded.parent) {
          role = 'parent'
          await auth().signOut();
          // throw "usuario padre no tiene acceso";
          throw new Error('Family license does not have access, please enter the Android or IOS app');
        }
        // Cookie.set('access_token', token)
        const { email, uid } = auth().currentUser;
        console.log(auth().currentUser)
        commit('SET_USER', { email, uid, role, token });
      } catch (error) {
        console.log(error)
        throw error;
      }
    },
    async loginGoogle ({ commit }, emailTemp) {
      // const token = await auth().currentUser.getIdToken();
      // const decoded = JWTDecode(token);
      try {  
        const token = await auth().currentUser.getIdToken();
        const decoded = JWTDecode(token);
        console.log(decoded)
        let role = null;
        if (decoded.superAdmin) {
          role = 'superAdmin'
        }
        if (decoded.schoolAdmin) {
          role = 'schoolAdmin'
        }
        if (decoded.schoolTeacher) {
          role = 'schoolTeacher'
        }
        if (decoded.parent) {
          role = 'parent'
        }
        if (decoded.individualTeacher) {
          role = 'individualTeacher'
        }
        if (decoded.student) {
          role = 'student'
        }
        // Cookie.set('access_token', token)
        const { email, uid } = auth().currentUser;
        if (uid != '' && email == '')
          email = emailTemp;
        commit('SET_USER', { email, uid, role, token });
      } catch (error) {
        console.log(error)
        throw error;
      }
    },
    async loginClever ({ commit }, nuevo) {
      try {  
        const token = await auth().currentUser.getIdToken(nuevo);
        const decoded = JWTDecode(token);
        console.log(decoded)
        let role = null;
        if (decoded.schoolTeacher) {
          role = 'schoolTeacher'
        }
        if (decoded.student) {
          role = 'student'
        }
        // Cookie.set('access_token', token)
        const { email, uid } = auth().currentUser;
  
        commit('SET_USER', { email, uid, role, token });
      } catch (error) {
        console.log(error)
        throw error;
      }
    }
  },
  modules: {
  }
})
