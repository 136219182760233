<template>
  <div>
    <v-btn color="rgba(2, 130, 124, 1)" class="boton-flotante" dark absolute  fab @click="drawer = true" >
        <v-icon>mdi-dots-vertical</v-icon>
    </v-btn>
    <v-navigation-drawer
      v-model="drawer"
      color="rgba(0, 143, 135, 141)"
      dark
      absolute
      temporary
      width="200"
    >
      <v-list-item class="pt-5">
        <v-avatar size="70" tile class="centrar-imagen">
          <img src="../assets/admin/logo-te.png">
        </v-avatar>
        <!-- <v-list-item-avatar size="150">
         
        </v-list-item-avatar> -->
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">Teacher Edition</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group v-model="group">
          <v-list-item href="/schoolTeacher" >
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
          <v-list-item href="/schoolTeacher/classroom">
            <v-list-item-icon>
              <img src="../assets/admin/clase.svg">
            </v-list-item-icon>
            <v-list-item-title>Classroom</v-list-item-title>
          </v-list-item>
          <v-list-item @click="OpenTe">
            <div class="icono-lateral">
              <img src="../assets/admin/teaching-tools.svg">
            </div>
            <v-list-item-title>Teaching Tools</v-list-item-title>
          </v-list-item>
          <v-list-item href="/schoolTeacher/statistics">
            <v-list-item-icon>
              <v-icon>mdi-align-vertical-bottom</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Reports</v-list-item-title>
          </v-list-item>
          <v-list-item href="/schoolTeacher/resources">
            <v-list-item-icon>
              <v-icon>mdi-file-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Resources</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item href="/schoolTeacher/settings">
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
        <v-list-item href="/schoolTeacher/help">
            <v-list-item-icon>
              <v-icon>mdi-help-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Help Center</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon size="30">mdi-logout-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <p class="subtitle-1">Logout</p>
            </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!-- <v-dialog v-model="dialogSettings" max-width="700">
      <v-card>
        <v-card-title class="headline">
          Set up your account
        </v-card-title>
        <v-row class="ma-5">
          <v-col class="ma-4">
            Change your password
          </v-col>
          <v-col>
            <v-text-field outlined label="Password" prepend-icon="mdi-lock" v-model="password" autocomplete="off"></v-text-field>
          </v-col>
          <v-col class="col-3">
            <v-btn class="ma-2" :loading="loadingPassword" :disabled="loadingPassword" color="secondary" @click="ChangePassword">
              Update
            </v-btn>
          </v-col>
        </v-row>
        <v-alert outlined type="warning" prominent border="left" class="ma-4" v-if="textUpdatePassword != ''">
           {{ textUpdatePassword }}
        </v-alert>
      </v-card>
    </v-dialog> -->
    <v-snackbar top v-model="snackbar" timeout="4000" color="blue-grey">
      se actualizo correctamente
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <router-view/>
  </div>
</template>
<script>
import { auth } from '../plugins/firebase'
export default {
  data: () => ({
    drawer: false,
    group: null,
    dialogSettings: false,
    loadingPassword: false,
    password: '',
    snackbar: false,
    textUpdatePassword: '',
    ButtonBakc: false,
  }),
  methods: {
    async logout () {
      await auth().signOut()
      localStorage.removeItem('email');
      localStorage.removeItem('uid');
      localStorage.removeItem('role');
      localStorage.removeItem('token');
      location.href = "/login";
    },
    settings () {
      this.dialogSettings = true;
    },
    OpenTe () {
      if (this.$route.name != "activitiesList") {
        location.href = "/schoolTeacher/activitieslist";
      }else{
        this.drawer =false;
      }
    },
    
  },
  mounted() {
    // let unityLoaderScript = document.createElement('script')
    // let unityProgressScript = document.createElement('script')
    // unityLoaderScript.setAttribute('src', '/unity/Build/UnityLoader.js')
    // unityProgressScript.setAttribute('src', '/unity/TemplateData/UnityProgress.js')
    // document.head.appendChild(unityLoaderScript)
    // document.head.appendChild(unityProgressScript)
  },
}
</script>
<style>
  .icono-lateral img{
    width: 25px;
    height: 25px;
    margin-right: 30px;
  }
  .centrar-imagen {
    margin-left: auto;
    margin-right: auto;
  }
  .boton-flotante {
    width: 45px !important;
    height: 45px !important;
  }
  @media only screen and (max-width: 1200px) {
    .boton-flotante {
      width: 40px !important;
      height: 40px !important;
    }
  }
  @media only screen and (max-width: 992px) {
    .boton-flotante {
      width: 35px !important;
      height: 35px !important;
    }
  }
  @media only screen and (max-width: 768px) {
    .boton-flotante {
      width: 30px !important;
      height: 30px !important;
    }
  }
</style>