<template>
  <div>
    <v-app-bar
       color="rgba(0, 143, 135, 141)"
      dense
      dark
    >
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <!-- <v-toolbar-title>Admin</v-toolbar-title> -->

      <v-spacer></v-spacer>

      <!-- <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn> -->

      <v-menu left bottom transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-dialog v-model="dialogSettings" max-width="700">
      <v-card>
        <v-card-title class="headline">
          Set up your account
        </v-card-title>
        <v-row class="ma-5">
          <v-col class="ma-4">
            Change your password
          </v-col>
          <v-col>
            <v-text-field outlined label="Password" prepend-icon="mdi-lock" v-model="password" autocomplete="off"></v-text-field>
          </v-col>
          <v-col class="col-3">
            <v-btn class="ma-2" :loading="loadingPassword" :disabled="loadingPassword" color="secondary" @click="ChangePassword">
              Update
            </v-btn>
          </v-col>
        </v-row>
        <v-alert outlined type="warning" prominent border="left" class="ma-4" v-if="textUpdatePassword != ''">
           {{ textUpdatePassword }}
        </v-alert>
      </v-card>
    </v-dialog>
    <v-snackbar top v-model="snackbar" timeout="4000" color="blue-grey">
      se actualizo correctamente
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <router-view/>
  </div>
</template>
<script>
import { auth } from '../plugins/firebase'
export default {
  data: () => ({
    drawer: false,
    group: null,
    dialogSettings: false,
    loadingPassword: false,
    password: '',
    snackbar: false,
    textUpdatePassword: ''
  }),
  methods: {
    async logout () {
      await auth().signOut()
      localStorage.removeItem('email');
      localStorage.removeItem('uid');
      localStorage.removeItem('role');
      localStorage.removeItem('token');
      location.href = "/login";
    },
    settings () {
      this.dialogSettings = true;
    },
    goRoute (page) {
      console.log('asd')
      this.$router.push({name: page});
    },
    ChangePassword () {
      if (this.password == '') {
        this.textUpdatePassword = 'incorrect password';
        setTimeout(() => (this.textUpdatePassword = ''), 3000);
        return;
      }
      this.loadingPassword=true;
      var user = auth().currentUser;
      // console.log(auth().currentUser)
      let aux=this;
        user.updatePassword(this.password).then(function() {          
          aux.dialogSettings = false;
          aux.loadingPassword=false;
          aux.snackbar =true;
        }).catch(function(error) {
          console.log(error)
          aux.loadingPassword=false;
          aux.textUpdatePassword = error.message;
          setTimeout(() => (aux.textUpdatePassword = ''), 3000);
        });
    },
    async Opentools () {
      const token = await auth().currentUser.getIdToken();
      console.log(token);
      window.location.href = "leelee://game?token="+token;
      console.log('asd')
    }
  },
  mounted() {
    // let unityLoaderScript = document.createElement('script')
    // let unityProgressScript = document.createElement('script')
    // unityLoaderScript.setAttribute('src', '/unity/Build/UnityLoader.js')
    // unityProgressScript.setAttribute('src', '/unity/TemplateData/UnityProgress.js')
    // document.head.appendChild(unityLoaderScript)
    // document.head.appendChild(unityProgressScript)
  },
}
</script>
<style>
  .icono-lateral img{
    width: 25px;
    height: 25px;
    margin-right: 30px;
  }
  .centrar-imagen {
    margin-left: auto;
    margin-right: auto;
  }

</style>
