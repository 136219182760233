import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'
import i18n from '../i18n'
import layoutSuperAdmin from '../layouts/superAdmin'
import layoutSchoolAdmin from '../layouts/schoolAdmin'
import layoutSchoolTeacher from '../layouts/schoolTeacher'
import layoutSchoolTeacher2 from '../layouts/schoolTeacher2'
import layoutIndividualTeacher from '../layouts/IndividualTeacher';
import layoutIndividualTeacher2 from '../layouts/IndividualTeacher2';
import layoutHomepage from '../layouts/Homepage'
import CleanLayout from '../layouts/CleanLayout'
import StudentLayout from '../layouts/students'
import JWTDecode from 'jwt-decode'

Vue.use(VueRouter)
const loginGuard = (to, from, next) => {
  //console.log(store.state.user)
  if (store.state.user.role != null){
    if (store.state.user.token == null) {
      //auth().signOut()
      localStorage.removeItem('email');
      localStorage.removeItem('uid');
      localStorage.removeItem('role');
      localStorage.removeItem('token');
      location.href = "/login";
    }else{
      next({ name: store.state.user.role })
    }
  }else{
    next()
  }
}
const loginStudentGuard = (to, from, next) => {
  //console.log(store.state.user)
  if (store.state.user.role != null){
    if (store.state.user.token == null) {
      //auth().signOut()
      localStorage.removeItem('email');
      localStorage.removeItem('uid');
      localStorage.removeItem('role');
      localStorage.removeItem('token');
      location.href = "/login";
    }else{
      next({ name: 'studentEdition' })
    }
  }else{
    next()
  }
}
const AuthSuperAdmin = (to, from, next) => {
  // console.log(store.state.user)
  if (store.state.user.role != 'superAdmin'){
    next({ name: 'Home' })
  }else{
    next()
  }
}
const AuthSchoolTeacher = (to, from, next) => {
  //const currentUser = auth().currentUser;
  //console.log(currentUser)
  // console.log(store.state.user)
  if (store.state.user.role != 'schoolTeacher' && store.state.user.role != 'individualTeacher'){
    next({ name: 'Login' })
  }else{
    let token = localStorage.getItem('token');
    const decoded = JWTDecode(token);
    let fecha= new Date(decoded.auth_time*1000);
    let hoy = new Date();
    fecha.setHours(0,0,0,0);
    hoy.setHours(0,0,0,0);
    if (fecha.getTime() == hoy.getTime()){
      next()
    }else{
      localStorage.removeItem('email');
      localStorage.removeItem('uid');
      localStorage.removeItem('role');
      localStorage.removeItem('token');
      location.href = "/login";
    }
  }
}
const AuthSchoolAdmin = (to, from, next) => {
  // console.log(store.state.user)
  if (store.state.user.role !== 'schoolAdmin'){
    next({ name: 'Login' })
  }else{
    next()
  }
}
const AuthStudent = (to, from, next) => {
   console.log(store.state.user)
  if (store.state.user.role !== 'student'){
    next({ name: 'Login' })
  }else{
    console.log(process.env.VUE_APP_VERSION)
    next()
  }
}


const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`
  },
  {
    path: '/oauth2',
    name: 'oauth2',
    component: () => import('../views/auth/oauth2.vue'),
    meta: { layout: layoutHomepage }
  },
  {
    path: '/oauth2Demo',
    name: 'oauth2Demo',
    component: () => import('../views/auth/oauth2Demo.vue'),
    meta: { layout: layoutHomepage }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/termsandconditions',
    name: 'Termsandconditions',
    component: () => import('../views/TermsConditions.vue'),
    meta: { layout: layoutHomepage }
  },
  {
    path: '/termsofservices',
    name: 'Termsofservices',
    component: () => import('../views/TermsService.vue'),
    meta: { layout: layoutHomepage }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue'),
    meta: { layout: layoutHomepage }
  },
  {
    path: '/licensetermsuse',
    name: 'Licensetermsuse',
    component: () => import('../views/LicenseTermsUse.vue'),
    meta: { layout: layoutHomepage }
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: () => import('../views/ForgotPassword.vue'),
    meta: { layout: layoutHomepage }
  },
  {
    path: '/errorLog',
    name: 'LoginError',
    component: () => import('../views/auth/LoginError.vue'),
    meta: { layout: layoutHomepage }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    beforeEnter: loginGuard,
    meta: { layout: layoutHomepage }
  },
  {
    path: '/login-student',
    name: 'LoginStudent',
    component: () => import('../views/LoginStudent.vue'),
    beforeEnter: loginStudentGuard,
    meta: { layout: layoutHomepage }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('../views/auth/Signup.vue'),
    props:true,
    meta: { layout: layoutHomepage }
  },
  {
    path: '/superAdmin',
    name: 'superAdmin',
    component: () => import('../views/admin/superAdmin/Main.vue'),
    beforeEnter: AuthSuperAdmin,
    meta: { requiresAuth:true },
    meta: { layout: layoutSuperAdmin }
  },
  {
    path: '/superAdmin/schoolAdmin',
    name: 'crudSchoolAdmin',
    component: () => import('../views/admin/superAdmin/SchoolAdmin.vue'),
    beforeEnter: AuthSuperAdmin,
    meta: { requiresAuth:true },
    meta: { layout: layoutSuperAdmin }
  },
  {
    path: '/superAdmin/schools',
    name: 'schools',
    component: () => import('../views/admin/superAdmin/Schools.vue'),
    beforeEnter: AuthSuperAdmin,
    meta: { requiresAuth:true },
    meta: { layout: layoutSuperAdmin }
  },
  {
    path: '/superAdmin/crudIndividualTeacher',
    name: 'crudIndividualTeacher',
    component: () => import('../views/admin/superAdmin/IndividualTeacher.vue'),
    beforeEnter: AuthSuperAdmin,
    meta: { requiresAuth:true },
    meta: { layout: layoutSuperAdmin }
  },
  {
    path: '/superAdmin/schoolDistrict',
    name: 'schoolDistrict',
    component: () => import('../views/admin/superAdmin/SchoolDistrict.vue'),
    beforeEnter: AuthSuperAdmin,
    meta: { requiresAuth:true },
    meta: { layout: layoutSuperAdmin }
  },
  {
    path: '/superAdmin/states',
    name: 'mangeStates',
    component: () => import('../views/admin/superAdmin/States.vue'),
    beforeEnter: AuthSuperAdmin,
    meta: { requiresAuth:true },
    meta: { layout: layoutSuperAdmin }
  },
  {
    path: '/superAdmin/countries',
    name: 'mangeCountries',
    component: () => import('../views/admin/superAdmin/Countries.vue'),
    beforeEnter: AuthSuperAdmin,
    meta: { requiresAuth:true },
    meta: { layout: layoutSuperAdmin }
  },
  {
    path: '/superAdmin/importar',
    name: 'importar',
    component: () => import('../views/admin/superAdmin/ImportarDatos.vue'),
    beforeEnter: AuthSuperAdmin,
    meta: { requiresAuth:true },
    meta: { layout: layoutSuperAdmin }
  },
  {
    path: '/individualTeacher',
    name: 'individualTeacher',
    component: () => import('../views/admin/individualTeacher/index.vue'),
    beforeEnter: AuthSchoolTeacher,
    meta: { layout: layoutIndividualTeacher }
  },
  {
    path: '/individualTeacher/resources',
    name: 'resources2',
    component: () => import('../views/admin/schoolTeacher/Resources.vue'),
    beforeEnter: AuthSchoolTeacher,
    meta: { layout: layoutIndividualTeacher }
  },
  {
    path: '/individualTeacher/activitieslist',
    name: 'activitiesListIndividual',
    component: () => import('../views/admin/schoolTeacher/ActivitiesList.vue'),
    beforeEnter: AuthSchoolTeacher,
    meta: { requiresAuth:true },
    meta: { layout: layoutIndividualTeacher2 }
  },
  {
    path: '/individualTeacher/settings',
    name: 'accountSettingsIndividual',
    component: () => import('../views/admin/schoolTeacher/Settings.vue'),
    beforeEnter: AuthSchoolTeacher,
    meta: { requiresAuth:true },
    meta: { layout: layoutIndividualTeacher }
  },
  {
    path: '/schoolAdmin',
    name: 'schoolAdmin',
    component: () => import('../views/admin/schoolAdmin/Main.vue'),
    meta: { requiresAuth:true },
    meta: { layout: layoutSchoolAdmin }
  },
  {
    path: '/schoolAdmin/students',
    name: 'crudStudents',
    component: () => import('../views/admin/schoolAdmin/Students.vue'),
    meta: { requiresAuth:true },
    meta: { layout: layoutSchoolAdmin }
  },
  {
    path: '/schoolTeacher',
    name: 'schoolTeacher',
    component: () => import('../views/admin/schoolTeacher/index.vue'),
    beforeEnter: AuthSchoolTeacher,
    //meta: { requiresAuth:true },
    //meta: { layout: layoutSchoolTeacher }
    meta: { title: 'Teacher Edition', requiresAuth: true, layout: layoutSchoolTeacher }
  },
  { path: '/schoolTeacher/students', redirect: '/schoolTeacher/classroom' },
  {
    path: '/schoolTeacher/classroom',
    name: 'studentsTeacher',
    component: () => import('../views/admin/schoolTeacher/Main.vue'),
    beforeEnter: AuthSchoolTeacher,
    //meta: { requiresAuth:true },
    //meta: { layout: layoutSchoolTeacher }
    meta: { title: 'Classroom', requiresAuth: true, layout: layoutSchoolTeacher }
  },
  {
    path: '/schoolTeacher/classroom/:class',
    name: 'classroom',
    component: () => import('../views/admin/schoolTeacher/Classroom.vue'),
    beforeEnter: AuthSchoolTeacher,
    //meta: { requiresAuth:true },
    //meta: { layout: layoutSchoolTeacher }
    meta: { title: 'Classroom - Students', requiresAuth: true, layout: layoutSchoolTeacher }
  },
  {
    path: '/schoolTeacher/statistics',
    name: 'statistics',
    component: () => import('../views/admin/schoolTeacher/Statistics.vue'),
    beforeEnter: AuthSchoolTeacher,
    //meta: { requiresAuth:true },
    //meta: { layout: layoutSchoolTeacher }
    meta: { title: 'Reports', requiresAuth: true, layout: layoutSchoolTeacher }
  },
  {
    path: '/schoolTeacher/teachingtools',
    name: 'teachingtools',
    component: () => import('../views/admin/schoolTeacher/TeachingTools.vue'),
    beforeEnter: AuthSchoolTeacher,
    meta: { requiresAuth:true },
    meta: { layout: layoutSchoolTeacher }
  },
  {
    path: '/schoolTeacher/tools/:mission',
    name: 'tools',
    component: () => import('../views/admin/schoolTeacher/Tools.vue'),
    meta: { requiresAuth:true },
    meta: { layout: layoutSchoolTeacher }
  },
  {
    path: '/schoolTeacher/activitieslist',
    name: 'activitiesList',
    component: () => import('../views/admin/schoolTeacher/ActivitiesList.vue'),
    beforeEnter: AuthSchoolTeacher,
    //meta: { requiresAuth:true },
    //meta: { layout: layoutSchoolTeacher2 }
    meta: { title: 'Teaching Tools', requiresAuth: true, layout: layoutSchoolTeacher2 }
  },
  {
    path: '/schoolTeacher/resources',
    name: 'resources',
    component: () => import('../views/admin/schoolTeacher/Resources.vue'),
    beforeEnter: AuthSchoolTeacher,
    //meta: { title: 'Resources' },
    //meta: { requiresAuth:true },
    meta: { title: 'Resources', requiresAuth: true, layout: layoutSchoolTeacher }
  },
  {
    path: '/schoolTeacher/settings',
    name: 'accountSettings',
    component: () => import('../views/admin/schoolTeacher/Settings.vue'),
    beforeEnter: AuthSchoolTeacher,
    //meta: { requiresAuth:true },
    //meta: { layout: layoutSchoolTeacher }
    meta: { title: 'Settings', requiresAuth: true, layout: layoutSchoolTeacher }
  },
  {
    path: '/schoolTeacher/help',
    name: 'helpCenter',
    component: () => import('../views/admin/schoolTeacher/Help.vue'),
    beforeEnter: AuthSchoolTeacher,
    //meta: { requiresAuth:true },
    //meta: { layout: layoutSchoolTeacher }
    meta: { title: 'Help', requiresAuth: true, layout: layoutSchoolTeacher }
  },
  {
    path: '/student',
    name: 'student',
    component: () => import('../views/admin/student/index.vue'),
    beforeEnter: AuthStudent,
    meta: { requiresAuth:true },
    meta: { layout: StudentLayout }
  },
  {
    path: '/student-edition',
    name: 'studentEdition',
    component: () => import('../views/admin/student/leeleese.vue'),
    meta: { requiresAuth:true },
    meta: { layout: StudentLayout }
  },
  {
    path: '/sedemo',
    name: 'studentEditionDemo',
    component: () => import('../views/admin/student/studentEditionDemo.vue'),
    meta: { requiresAuth:true },
    meta: { layout: StudentLayout }
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('../views/Pricing.vue'),
    meta: { layout: layoutHomepage }
  },
  {
    path: '/request-a-quote',
    name: 'quote',
    component: () => import('../views/Quote.vue'),
    meta: { layout: layoutHomepage }
  },
  {
    path: '/select-plan',
    name: 'oferts',
    component: () => import('../views/payments/products.vue'),
    meta: { layout: CleanLayout }
  },
  {
    path: '/success',
    name: 'payment-success',
    component: () => import('../views/payments/success.vue'),
    meta: { layout: CleanLayout }
  },
  {
    path: '/license-finish',
    name: 'LicenseFinish',
    component: () => import('../views/admin/student/licenseFinish.vue'),
    meta: { layout: CleanLayout }
  },
  {
    path: '/cancel',
    name: 'payment-cancel',
    component: () => import('../views/payments/cancel.vue'),
    meta: { layout: CleanLayout }
  },
  {
    path: '/:lang',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { layout: layoutHomepage }
      },
      {
        path: 'resources',
        name: 'Resources',
        component: () => import('../views/Resources.vue'),
        meta: { layout: layoutHomepage }
      },
      {
        path: 'leeleeapp',
        name: 'App',
        component: () => import('../views/AppLeelee.vue'),
        meta: { layout: layoutHomepage }
      },
      
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {console.log('to-->');console.log(to);
  Vue.nextTick( () => {
    document.title = to.meta.title ? to.meta.title : 'Clase Leelee';
  });

  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  
  if(requiresAuth) {
    if (localStorage.getItem('role') !== null) {
      next()
    } else {
      next({
        path: '/login',
      })
    }
  } else next()
});

export default router
