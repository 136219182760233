<template>
  <div>
    <v-card>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
      app
      dark
      color="rgba(0, 143, 135, 141)"
      mini-variant-width="100"
      width="200"
    >
      <v-list-item class="pt-5">
        <v-avatar size="80" tile>
          <img src="../assets/admin/logo-te.png">
        </v-avatar>

        <v-list-item-title>Teacher</v-list-item-title>

        <v-btn
          icon
          @click.stop="mini = !mini"
        >
          <v-icon size="60">mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense two-line>
        <v-list-item to="/individualTeacher">
          <v-list-item-icon>
            <v-icon size="30">mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <p class="subtitle-1">Dashboard</p>
            <!-- <v-list-item-title>Dashboard</v-list-item-title> -->
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/individualTeacher/activitieslist" v-if="typeUser == 'full'">
          <v-list-item-icon>
              <img src="../assets/admin/teaching-tools.svg" style="width: 30px; height: 30px;">
            </v-list-item-icon>

          <v-list-item-content>
            <p class="subtitle-1 my-auto">Teaching Tools</p>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/individualTeacher/resources">
          <v-list-item-icon>
              <v-icon size="30">mdi-file-cog</v-icon>
            </v-list-item-icon>

          <v-list-item-content>
            <p class="subtitle-1">Resources</p>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item to="/schoolTeacher/statistics">
          <v-list-item-icon>
              <v-icon size="50">mdi-chart-box-outline</v-icon>
            </v-list-item-icon>

          <v-list-item-content>
            <p class="text-h6">Reports</p>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item to="/individualTeacher/settings">
          <v-list-item-icon>
              <v-icon size="30">mdi-cog</v-icon>
            </v-list-item-icon>

          <v-list-item-content>
            <p class="subtitle-1">Settings</p>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon size="30">mdi-logout-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <p class="subtitle-1">Logout</p>
            </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main app>
        <v-snackbar top v-model="snackbar" timeout="4000" color="blue-grey">
            se actualizo correctamente
            <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                Close
            </v-btn>
            </template>
        </v-snackbar>
        <router-view/>
    </v-main>
  </v-card>

    

  </div>
</template>
<script>
import { auth,db } from '../plugins/firebase'
export default {
  data: () => ({
    drawer: true,
    group: null,
    dialogSettings: false,
    loadingPassword: false,
    password: '',
    snackbar: false,
    textUpdatePassword: '',
    ButtonBakc: false,
    mini: true,
    typeUser: ''
  }),
  created(){
    
  },
  methods: {
    async logout () {
      await auth().signOut()
      localStorage.removeItem('email');
      localStorage.removeItem('uid');
      localStorage.removeItem('role');
      localStorage.removeItem('token');
      location.href = "/login";
    },
    settings () {
      this.dialogSettings = true;
    },
    goRoute (page) {
      console.log('asd')
      this.$router.push({name: page});
    },
    async GetInfoUser(){
      let iud = this.$store.state.user.uid;
      let info = await db.collection("IndividualTeacher").doc(iud).get();
      if (info.data().Free){
        console.log("asdasd")
        this.typeUser = 'free';
      }
      if (info.data().Full) {
        this.typeUser = 'full';
      }
    }
  },
  mounted() {
    console.log(this.$store.state.user.role)
    this.GetInfoUser();
    if (this.$store.state.user.role != "individualTeacher") {
      this.logout();
    }
    // let unityLoaderScript = document.createElement('script')
    // let unityProgressScript = document.createElement('script')
    // unityLoaderScript.setAttribute('src', '/unity/Build/UnityLoader.js')
    // unityProgressScript.setAttribute('src', '/unity/TemplateData/UnityProgress.js')
    // document.head.appendChild(unityLoaderScript)
    // document.head.appendChild(unityProgressScript)
  },
}
</script>
<style>
  .icono-lateral img{
    width: 25px;
    height: 25px;
    margin-right: 30px;
  }
  .centrar-imagen {
    margin-left: auto;
    margin-right: auto;
  }
.test {
  width:200px;
  display:inline-block;
  overflow: auto;
  white-space: nowrap;
  margin:0px auto;
  border:1px red solid;
}
</style>
