<template>

  <div>
      <div id="id">
          <v-app-bar class="main-menu" height="110" dense>
            <v-menu class="hidden-md-and-up" v-if="$vuetify.breakpoint.mobile">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item :to="`/${$i18n.locale}/`">
                  <v-list-item-title>{{ $t('homepage.layout.appBarHome') }}</v-list-item-title>
                </v-list-item>
                <v-list-item :to="`/${$i18n.locale}/resources`">
                  <v-list-item-title>{{ $t('homepage.layout.appBarResources') }}</v-list-item-title>
                </v-list-item>
                <v-list-item :to="`/${$i18n.locale}/leeleeapp`">
                  <v-list-item-title>{{ $t('homepage.layout.appBarApp') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          
            <template v-else>
              
            <router-link :to="`/${$i18n.locale}/`"  class="ma-5">
              <img src="../assets/homepage/main-logo.png" id="logo">
            </router-link>
            <router-link :to="`/${$i18n.locale}/`"  class="nav-link text-h6">{{ $t('homepage.layout.appBarHome') }}</router-link>
            <p @click="aboutus" class="nav-link my-md-auto text-h6">{{ $t('homepage.layout.appBarAbout') }}</p>
            <router-link :to="`/${$i18n.locale}/resources`" class="nav-link active text-h6" aria-current="page" >{{ $t('homepage.layout.appBarResources') }}</router-link>
            <router-link :to="`/${$i18n.locale}/leeleeapp`" class="nav-link text-h6" href="#" tabindex="-1" aria-disabled="true">{{ $t('homepage.layout.appBarApp') }}</router-link>
            </template>
            <v-spacer></v-spacer>
            <div class="my-md-auto idiomas">
              <v-select class="ma-2 pt-6" prepend-icon="mdi-web" v-model="$i18n.locale" :items="langs" item-value="value" item-text="text" dense solo @change="setLocale"></v-select>
            </div>
            <v-btn class="ma-2 btn-signup" href="/login" color="white">Log in</v-btn>
            <!-- <v-btn class="ma-2 btn-signup" href="/signup">Sign up</v-btn> -->
            
          </v-app-bar>
          
      </div>

      <div>
        <transition 
          name="fade" 
          mode="out-in" 
          @beforeLeave="beforeLeave"
          @enter="enter"
          @afterEnter="afterEnter"
          >
          <router-view/>
        </transition>
      </div>

      <footer class="left-align">
        <div class="container">
          <div class="row">
            <div class="col">
              <p class="text-18 paddingb-10"><strong>{{ $t('homepage.layout.footerAbout') }}</strong></p>
              <p>
                <router-link :to="`/termsandconditions`" class="link-footer">{{ $t('homepage.layout.footerTerms') }}</router-link>
              </p>
              <p>
                <router-link :to="`/licensetermsuse`" class="link-footer">{{ $t('homepage.layout.footerLicense') }}</router-link>
              </p>
              <p>
                <router-link :to="`/privacy-policy`" class="link-footer">{{ $t('homepage.layout.privacy') }}</router-link>
              </p>
              <p>
                <router-link :to="`/${$i18n.locale}/leeleeapp`" class="link-footer">{{ $t('homepage.layout.footerApp') }}</router-link>
              </p>
            </div>
            <div class="col">
              <p class="text-18 paddingb-10"><strong>{{ $t('homepage.layout.footerServices') }}</strong></p>
              <p @click="servicesLink" class="c-pointer">{{ $t('homepage.layout.footerServices2') }}</p>
              <p @click="servicesLink" class="c-pointer">{{ $t('homepage.layout.footerServices3') }}</p>
              <p @click="servicesLink" class="c-pointer">{{ $t('homepage.layout.footerServices4') }}</p>
              <p @click="servicesLink" class="c-pointer">{{ $t('homepage.layout.footerServices5') }}</p>
            </div>
            <div class="col">
              <p class="text-18 paddingb-10"><strong>{{ $t('homepage.layout.footerContact') }}</strong></p>
              <div style="height:55px;">
                  <a href="mailto:info@claseleelee.com" class="texto-email yellow darken-3 pa-4 d-inline-block">
                    Email: info@claseleelee.com                
                  </a>
                  <a href="mailto:info@claseleelee.com" class="cyan accent-4 pa-4 c-pointer d-inline-block">
                    <img src="../assets/homepage/send.svg" width="17px" height="17px">
                  </a>
              </div>
              <div style="height:55px;">
                  <a href="mailto:support@claseleelee.com" class="texto-email yellow darken-3 pa-4 d-inline-block my-2">
                    Email: support@claseleelee.com                
                  </a>
                  <a href="mailto:support@claseleelee.com" class="cyan accent-4 pa-4 c-pointer d-inline-block my-2">
                    <img src="../assets/homepage/send.svg" width="17px" height="17px">
                  </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div style="background-color: #343434;" class="texto-copyright">
        <p class="ma-0">&copy; Copyright 2021 Escuela de Lectura LLC, APP Lee Lee</p>
      </div>
  </div>
</template>

<script>
// import i18n from '@/plugins/i18n'

export default {
  mounted(){
   
  },
  data(){
    return {
      langs: [
        {value: 'en', text:'EN'},
        {value: 'es' , text: 'ES'}
      ],
      prevHeight: 0,
    }
  },
  methods: {
    changeLang(lang){
    //   this.lang = lang;
    //   this.$i18n.locale = lang;
    //   console.log(this.lang);
    //   console.log("Lang: " + this.$i18n.locale);

    },
    setLocale(){
      this.$router.push({
        params: { lang:this.$i18n.locale}
      });
    },
    aboutus(){
      console.log(this.$route.name);
      if (this.$route.name != 'Home') {
        this.$router.push({path: '/', hash: 'aboutus'})
      }else{
        location.hash = "#aboutus";
      }
    },
    servicesLink(){
      if (this.$route.name != 'Home') {
        this.$router.push({path: '/', hash: 'services'});
      }else{
        location.hash = "#services";
      }
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
  },
  watch:{
    'this.$route.name'(value){
      console.log(value)
    }
  }
}
</script>

<style>
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.3s;
    transition-property: height, opacity;
    transition-timing-function: ease;
    overflow: hidden;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }
  footer{
    background-color: #343434;
    padding: 40px 0;
    color: white;
  }
  footer p{
    margin-bottom: 0;
  }
  .btn-signup{
    color: rgb(116,203,230);
    border: 1px solid rgb(116,203,230);
  }
  .btn-signup img{
    width: 20px;
  }
  .main-menu{
    background: rgb(116,203,230);
    background: linear-gradient(90deg, rgba(116,203,230,1) 0%, rgba(116,203,230,1) 38%, rgba(142,251,184,1) 100%);
  }
  .main-menu .nav-link{
    font-weight: bold;
    color: white !important;
    width: 120px;
    text-align: center;
    text-decoration: none;
  }
  #logo{
    width: 120px;
    margin: 0 20px 0 0;
  }
  .link-footer{
    color: #fff !important;
    text-decoration: none;
  }
  .link-footer:hover{
    color: skyblue;
  }
  .texto-email{
    color: #000 !important;
    text-decoration: none;
  }
  .texto-copyright{
    text-align: center;
    color: white;
    font-size: 14px;
  }
  .idiomas{
    max-width: 150px;
  }
</style>