<template>
  <div>
    <v-app-bar
      color="rgba(0, 143, 135, 141)"
      dense
      dark
    >
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title>Admin</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>
<!--
      <v-btn icon @click="logout">
        <v-icon>mdi-logout-variant</v-icon>
      </v-btn> -->
      <v-menu
        left
        bottom
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="settings">
            <v-list-item-action>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      class="light-blue accent-2"
      dark
      absolute
      temporary
    >
        <v-list-item class="pt-10">
          <v-list-item-avatar>
            <img src="../assets/admin/sombrero-de-graduacion.svg">
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item>
            <v-list-item-content>
            <v-list-item-title class="title">Teacher Edition</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list
        flat
      >
        <v-list-item-group
          v-model="group"
          active-class="light-blue accent-4"
        >
          <v-list-item to="/superAdmin/crudIndividualTeacher">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Individual Teacher</v-list-item-title>
          </v-list-item>
          <v-list-item to="/superAdmin/states">
            <v-list-item-icon>
              <v-icon>mdi-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>States</v-list-item-title>
          </v-list-item>
          <v-list-item to="/superAdmin/schoolDistrict">
            <v-list-item-icon>
              <v-icon>mdi-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>School District</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item to="/superAdmin/countries">
            <v-list-item-icon>
              <v-icon>mdi-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Countries</v-list-item-title>
          </v-list-item> -->
          <v-list-item to="/superAdmin/schools">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>School & Licences</v-list-item-title>
          </v-list-item>
          <v-list-item to="/superAdmin/schoolAdmin">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>School Admin</v-list-item-title>
          </v-list-item>
          <v-list-item to="/superAdmin">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Super Admin</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- <div class="container mt-5">
      <v-alert type="warning" v-if="$nuxt.isOffline">Estas sin conexion a internet</v-alert>
      <Nuxt />
    </div> -->
    <router-view/>
  </div>
</template>
<script>
import { auth } from '../plugins/firebase'
export default {
  data: () => ({
    drawer: false,
    group: null,
  }),
  methods: {
    async logout () {
      await auth().signOut()
      localStorage.removeItem('email');
      localStorage.removeItem('uid');
      localStorage.removeItem('role');
      localStorage.removeItem('token');
      location.href = "/";
    },
    settings () {
      console.log('asd');
    }
  }
}
</script>
